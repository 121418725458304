import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
import PublicLayout from 'layout/PublicLayout';

// public page
const LandingPage = Loadable(lazy(() => import('views/public/LandingPage')));
// ==============================|| AUTHENTICATION ROUTING ||============================== //

const PublicRoutes = {
    path: '/',
    element: <PublicLayout />,
    children: [
        {
            path: '/',
            element: <LandingPage />
        }
    ]
};

export default PublicRoutes;
