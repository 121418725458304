import { Outlet } from 'react-router-dom';
import { styled, useTheme } from '@mui/material/styles';
import { AppBar, Box, CssBaseline, Toolbar, useMediaQuery } from '@mui/material';

import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { SET_MENU } from 'store/actions';
import Header from './Header';
// ==============================|| PUBLIC LAYOUT ||============================== //

const PublicLayout = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('lg'));
    useEffect(() => {
        dispatch({ type: SET_MENU, opened: !matchDownMd });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [matchDownMd]);
    return (
        <>
            <Box sx={{ display: 'flex', background: theme.palette.primary.light }}>
                <CssBaseline />
                {/* header */}
                <AppBar
                    enableColorOnDark
                    position="fixed"
                    color="inherit"
                    elevation={0}
                    sx={{
                        bgcolor: theme.palette.background.default
                    }}
                >
                    <Toolbar sx={{ boxShadow: 1 }}>
                        <Header />
                    </Toolbar>
                </AppBar>
                <Outlet />
            </Box>
        </>
    );
};
export default PublicLayout;
